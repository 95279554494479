.ImagesGridUI__image___ZcjoF {
  height: 100%;
  position: relative;
  border-radius: 0.25rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.ImagesGridUI__overlay___1PLDg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FAFAFA;
}
.ImagesGridUI__zoom-overlay___1jOxB {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FAFAFA;
  opacity: 0;
  font-size: 2rem;
}
.ImagesGridUI__zoom-overlay___1jOxB:hover {
  opacity: 1;
}
