:root {
  --primary: #16D4E6;
  --primaryD20: #0d7e89;
  --primaryD15: #0f94a0;
  --primaryD10: #12a9b8;
  --primaryD05: #14bfcf;
  --primaryL60: #ffffff;
  --primaryL45: #e5fbfd;
  --primaryL05: #2bdaeb;
  --primaryF60: rgba(22, 212, 230, 0.6);
  --primaryF50: rgba(22, 212, 230, 0.5);
  --primaryF20: rgba(22, 212, 230, 0.2);
  --primaryF10: rgba(22, 212, 230, 0.1);
  --primaryF05: rgba(22, 212, 230, 0.05);
  --gradientEnd: #ace500;
  --blackShadow: rgba(0, 0, 0, 0.12);
  --unbanSelectableBorder: #6cd425;
  --unbanSelectableBackground: rgba(108, 212, 37, 0.15);
}
.MusicalGenres__tag___16KVE {
  display: flex;
  height: 3.75rem;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  justify-content: start;
  color: #767676;
  border: 1px solid #E0E0E0;
}
.MusicalGenres__tag___16KVE:hover {
  background-color: #FAFAFA;
}
.MusicalGenres__tag___16KVE i {
  font-size: 2rem;
  line-height: 2rem;
}
.MusicalGenres__selected___OA7gp {
  border: 1px solid var(--primary);
  background-color: var(--primaryF20);
}
.MusicalGenres__selected___OA7gp:hover {
  background-color: var(--primaryF20);
}
