.FormInput__input___1F5xS {
  width: 100%;
  outline: none;
  display: block;
  box-shadow: none;
  font-weight: 400;
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
  border: 1px solid #C0C0C0;
  background-color: #FFFFFF;
}
.FormInput__input___1F5xS:focus {
  border: 1px solid var(--primary);
}
.FormInput__input___1F5xS::placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: #767676;
  font-family: Proxima Nova;
}
.FormInput__input___1F5xS:disabled {
  color: #A5A8A8;
  background-color: #F2F2F2;
}
.FormInput__input___1F5xS[type=number]::-webkit-inner-spin-button,
.FormInput__input___1F5xS[type=number]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.FormInput__input-active___1H2_k {
  border: 1px solid var(--primary);
}
.FormInput__input-error___17ZBG {
  border: 1px solid #F85555;
}
.FormInput__input-error___17ZBG:focus {
  border: 1px solid #F85555;
}
