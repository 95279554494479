.PublicationPreview__wrapper___2-xaM {
  width: 100%;
  max-width: 45.5rem;
  background-color: #1f2626;
  overflow: hidden;
}
.PublicationPreview__top___27Krg {
  padding: 2rem;
  width: 100%;
  z-index: 1;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.PublicationPreview__bottom___2TyxJ {
  padding: 2rem;
  width: 100%;
  z-index: 1;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.PublicationPreview__youtubeUrl___ZA1Bg {
  color: white;
}
.PublicationPreview__urlContainer___20DEn {
  padding: 2rem;
}
