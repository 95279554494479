:root {
  --primary: #16D4E6;
  --primaryD20: #0d7e89;
  --primaryD15: #0f94a0;
  --primaryD10: #12a9b8;
  --primaryD05: #14bfcf;
  --primaryL60: #ffffff;
  --primaryL45: #e5fbfd;
  --primaryL05: #2bdaeb;
  --primaryF60: rgba(22, 212, 230, 0.6);
  --primaryF50: rgba(22, 212, 230, 0.5);
  --primaryF20: rgba(22, 212, 230, 0.2);
  --primaryF10: rgba(22, 212, 230, 0.1);
  --primaryF05: rgba(22, 212, 230, 0.05);
  --gradientEnd: #ace500;
  --blackShadow: rgba(0, 0, 0, 0.12);
  --unbanSelectableBorder: #6cd425;
  --unbanSelectableBackground: rgba(108, 212, 37, 0.15);
}
.MediaUploaderCarousel__carousel-wrapper___3KTDU {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #F1F1F1;
}
.MediaUploaderCarousel__carousel-wrapper___3KTDU li {
  padding: 0;
}
.MediaUploaderCarousel__arrow___CHeg0 {
  cursor: pointer;
  padding: 2rem 2rem 1.1rem;
  color: white;
}
.MediaUploaderCarousel__arrow___CHeg0 i {
  font-size: 2rem;
}
.MediaUploaderCarousel__buttons-wrapper___QrPoN {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  padding: 0 1rem;
}
.MediaUploaderCarousel__button___3EMlx {
  color: white;
  font-size: 1.6rem;
  padding: 1.5rem 0;
  margin: 0 1rem;
  border: none;
  background-color: transparent;
  box-shadow: none;
  z-index: 10;
  outline: none;
}
.MediaUploaderCarousel__back___3PXG- {
  background-color: #F1F1F1;
}
.MediaUploaderCarousel__lite___3b7p6 {
  color: #16d4e6;
  font-size: 30px;
  display: flex;
  text-align: center;
  margin-left: 5rem;
}
.MediaUploaderCarousel__highlight___3PevU {
  background-color: var(--primaryF20);
}
.MediaUploaderCarousel__litevideo___2gsUi {
  color: #16d4e6;
  font-size: 14px;
  font-weight: 400;
}
