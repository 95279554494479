.HeroSections__container___1yQde {
  margin: 0 auto;
  padding: 32px 0;
  background-color: #F2F2F2;
}
.HeroSections__container___1yQde .HeroSections__header___2BgcN {
  padding: 0 2rem;
}
.HeroSections__container___1yQde .HeroSections__logo___3I1K0 {
  width: 120px;
}
.HeroSections__container___1yQde .HeroSections__footer___mjK0t {
  margin: 0 2rem;
}
.HeroSections__container___1yQde .HeroSections__main___3OMH5 {
  display: flex;
  align-items: center;
  padding-bottom: 55px;
  height: calc(90vh);
}
.HeroSections__container___1yQde .HeroSections__main--bottom___2s_Jv {
  padding: 0;
  height: auto;
}
.HeroSections__container___1yQde .HeroSections__left-section___1cZVE {
  display: none;
}
.HeroSections__container___1yQde .HeroSections__right-section___1Tzde {
  padding-left: 0;
}
.HeroSections__container___1yQde .HeroSections__link___2AOqK {
  margin-top: 0;
  font-weight: 600;
  border-width: 1px;
  border-radius: 2px;
}
@media (min-width: 769px) {
  .HeroSections__container___1yQde {
    padding: 32px 64px;
  }
  .HeroSections__container___1yQde .HeroSections__header___2BgcN,
  .HeroSections__container___1yQde .HeroSections__footer___mjK0t {
    margin: 0;
    padding: 0;
  }
  .HeroSections__container___1yQde .HeroSections__main___3OMH5 {
    height: calc(83vh);
  }
  .HeroSections__container___1yQde .HeroSections__main--bottom___2s_Jv {
    padding: 55px 0 24px 0;
  }
  .HeroSections__container___1yQde .HeroSections__main--body___2J0Mo {
    padding: 55px 0;
  }
  .HeroSections__container___1yQde .HeroSections__logo___3I1K0 {
    width: 190px;
  }
  .HeroSections__container___1yQde .HeroSections__link___2AOqK {
    font-size: 18px;
    margin-top: 2.5rem;
  }
  .HeroSections__container___1yQde .HeroSections__link--padding-x___3yycZ {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1024px) {
  .HeroSections__container___1yQde .HeroSections__left-section___1cZVE {
    display: block;
  }
  .HeroSections__container___1yQde .HeroSections__left-section___1cZVE h1 {
    font-size: 330%;
    font-weight: 700;
    line-height: 108%;
    color: #1F2626;
    text-transform: uppercase;
    font-family: Roboto Condensed;
  }
  .HeroSections__container___1yQde .HeroSections__left-section___1cZVE h1 span {
    color: var(--primary);
  }
  .HeroSections__container___1yQde .HeroSections__left-section___1cZVE h1 div > div {
    font-size: 44px;
    margin-top: 20px;
  }
  .HeroSections__container___1yQde .HeroSections__left-section___1cZVE p {
    font-size: 32px;
    margin-top: 23px;
    font-weight: 400;
    line-height: 109%;
    font-family: Proxima Nova;
    color: #575757;
  }
  .HeroSections__container___1yQde .HeroSections__right-section___1Tzde {
    padding-left: 40px;
  }
}
@media (min-width: 1197px) {
  .HeroSections__container___1yQde .HeroSections__left-section___1cZVE h1 {
    font-size: 430%;
  }
}
