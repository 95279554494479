.AlertUI__container___1UMDg {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10001;
  padding: 2rem;
}
.AlertUI__box___vHIsK {
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(192, 192, 192, 0.54);
  max-width: calc(100vw - 4rem);
}
.AlertUI__success___2pazZ {
  background: #E5F7D9;
  border: 1px solid #61CB19;
  color: #61CB19;
}
.AlertUI__error___2Tqaf {
  background: #FFD1D1;
  border: 1px solid #F85555;
  color: #F85555;
}
.AlertUI__warning___1I7hu {
  background: #FEFAE9;
  border: 1px solid #F8CD1C;
  color: #F8CD1C;
}
.AlertUI__close___iw2q- {
  color: #1f2626;
  align-self: flex-start;
  margin-left: 2rem;
  padding: 2.5rem;
}
.AlertUI__message___1Zk6- {
  color: #767676;
  white-space: pre-wrap;
  max-width: 250px;
  margin-top: 0.4rem;
}
