.VerificationBadge__badge___Sry73 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-left: 0.5rem;
}
.VerificationBadge__small___3-kBJ {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
}
.VerificationBadge__medium___1DJnL {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
