.InfluencerLoginUI__card___34IBJ {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--primary);
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.InfluencerLoginUI__card___34IBJ > h1 > div > strong {
  color: var(--primary);
}
.InfluencerLoginUI__button___LXjPI {
  margin: auto;
  display: block;
}
.InfluencerLoginUI__button___LXjPI:disabled {
  background-color: #767676;
  border: 1px solid #1f2626;
}
.InfluencerLoginUI__logo___2NlQy {
  width: 180px;
  height: auto;
  margin: 0 auto;
}
.InfluencerLoginUI__password___2CTEl {
  display: flex;
  font-size: 20px;
}
.InfluencerLoginUI__input___3seY9 {
  padding: 0.5rem 6rem !important;
}
.InfluencerLoginUI__showPassword___3zv2O {
  top: 1rem;
  right: 1rem;
  width: auto;
  cursor: pointer;
  font-size: 2.5rem;
  position: absolute;
  line-height: 2.5rem;
}
.InfluencerLoginUI__soldOut___1XLU6 {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  color: #767676;
  font-family: Proxima Nova;
}
.InfluencerLoginUI__terms___3bFjk {
  font-weight: 600;
  color: #767676;
}
