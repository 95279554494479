.GooglePlaceInput__input-wrapper___M4trt i {
  color: #767676 !important;
}
.GooglePlaceInput__input-wrapper___M4trt input {
  font-size: 1.8rem;
  color: #767676;
}
.GooglePlaceInput__list___39mzB {
  position: absolute;
  top: 100%;
  background-color: white;
  border-left: 2px solid #F1F1F1;
  border-right: 2px solid #F1F1F1;
  width: 100%;
  z-index: 10;
}
.GooglePlaceInput__item___33BYn {
  padding: 1rem 2rem;
  color: #767676;
  border-bottom: 2px solid #F1F1F1;
  cursor: pointer;
}
.GooglePlaceInput__item___33BYn:hover {
  background-color: #F1F1F1;
}
