.InvalidLinkPage__wrapper___361rz {
  background-color: #1f2626;
  width: 100vw;
  min-height: 100vh;
  padding: 15rem 2rem 5rem;
}
.InvalidLinkPage__error___2ME0E {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #F85555;
  padding: 1rem 4rem;
  color: white;
  font-size: 1.8rem;
}
.InvalidLinkPage__error___2ME0E i {
  font-size: 2.5rem;
}
.InvalidLinkPage__left-container___-ejW0 {
  padding-right: 3rem;
  margin-bottom: 4rem;
}
@media screen and (max-width: 930px) {
  .InvalidLinkPage__left-container___-ejW0 {
    max-width: container-max-width;
    padding-right: 0;
  }
}
.InvalidLinkPage__icon___1Uuqu {
  width: 48px;
  height: 48px;
  background-color: var(--primary);
  border-radius: 50%;
  padding-top: 8px;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 2px;
}
.InvalidLinkPage__icon___1Uuqu i {
  color: white;
  font-size: 3rem;
}
.InvalidLinkPage__sub___1LEMY {
  color: #C0C0C0;
  font-size: 2.1rem;
  line-height: 1.3;
}
.InvalidLinkPage__container___2Z3Tm {
  margin: 0 auto;
  max-width: 1024px;
}
@media screen and (max-width: 930px) {
  .InvalidLinkPage__container___2Z3Tm {
    padding: 0 2rem;
    max-width: container-max-width;
    margin: 0 auto;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
}
