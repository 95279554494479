.ParticipationFeedbackCard__globe-tail___poEow {
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: 0;
  height: 0;
  border-left: 2rem solid transparent;
  border-right: 0 solid transparent;
  border-top: 1rem solid #F1F1F1;
}
