.SelectableChip__container___6R3DR {
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid #F1F1F1;
}
.SelectableChip__container___6R3DR:hover:not(.SelectableChip__selected___3rwoT) {
  background-color: #F1F1F1;
}
.SelectableChip__selected___3rwoT {
  border: 1px solid var(--primary);
  background-color: var(--primaryL05);
  color: #FAFAFA;
}
.SelectableChip__selected___3rwoT a {
  color: var(--primaryD10);
}
