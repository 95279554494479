.RolesSelect__roles-select___3eIJT {
  gap: 3rem;
  display: flex;
  flex-direction: column;
}
.RolesSelect__roles-select___3eIJT .RolesSelect__card___2Q-fW {
  gap: 16px;
  width: 100%;
  display: flex;
  padding: 3rem;
  cursor: pointer;
  align-items: center;
  border-radius: 16px;
  transition: all 0.2s;
  border: 2px solid #E0E0E0;
  background-color: #FFFFFF;
}
.RolesSelect__roles-select___3eIJT .RolesSelect__card___2Q-fW:hover {
  border-color: var(--primary);
  background-color: var(--primaryF20);
}
.RolesSelect__roles-select___3eIJT .RolesSelect__card___2Q-fW > div h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: #272727;
}
.RolesSelect__roles-select___3eIJT .RolesSelect__card___2Q-fW > div p {
  font-size: 13px;
  font-weight: 400;
  color: #5E5E5E;
}
@media (min-width: 768px) {
  .RolesSelect__roles-select___3eIJT .RolesSelect__card___2Q-fW > div h3 {
    font-size: 18px;
  }
  .RolesSelect__roles-select___3eIJT .RolesSelect__card___2Q-fW > div p {
    font-size: 14px;
  }
}
