.SupportLinks__container___3l0Tj {
  left: 0;
  width: 100%;
  bottom: -12px;
  padding: 10px;
  position: absolute;
}
.SupportLinks__container--no-padding___1Pj-3 {
  padding: 0;
}
.SupportLinks__container___3l0Tj a {
  height: 100%;
  display: flex;
  cursor: pointer;
  padding: 2rem 3rem;
  white-space: nowrap;
  align-items: center;
  transition: all 0.2s;
  color: #767676;
}
.SupportLinks__container___3l0Tj a:hover {
  color: var(--primary);
}
.SupportLinks__container___3l0Tj a:first-child {
  padding-left: 0;
}
.SupportLinks__container___3l0Tj .SupportLinks__language___3cttV {
  cursor: pointer;
  font-size: 2.2rem;
  color: #767676;
}
.SupportLinks__container___3l0Tj .SupportLinks__language___3cttV.SupportLinks__selected___2HpPa {
  cursor: default;
  color: var(--primary) !important;
}
.SupportLinks__container___3l0Tj .SupportLinks__language___3cttV:not(.SupportLinks__selected___2HpPa):hover {
  color: var(--primaryL05);
}
.SupportLinks__container___3l0Tj .SupportLinks__language___3cttV:not(:last-child) {
  border-right: 1px solid #D9D9D9;
}
