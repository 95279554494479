:root {
  --primary: #16D4E6;
  --primaryD20: #0d7e89;
  --primaryD15: #0f94a0;
  --primaryD10: #12a9b8;
  --primaryD05: #14bfcf;
  --primaryL60: #ffffff;
  --primaryL45: #e5fbfd;
  --primaryL05: #2bdaeb;
  --primaryF60: rgba(22, 212, 230, 0.6);
  --primaryF50: rgba(22, 212, 230, 0.5);
  --primaryF20: rgba(22, 212, 230, 0.2);
  --primaryF10: rgba(22, 212, 230, 0.1);
  --primaryF05: rgba(22, 212, 230, 0.05);
  --gradientEnd: #ace500;
  --blackShadow: rgba(0, 0, 0, 0.12);
  --unbanSelectableBorder: #6cd425;
  --unbanSelectableBackground: rgba(108, 212, 37, 0.15);
}
.SafeImage__wrapper___1u0ta {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.SafeImage__background-image___n4PFC {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.SafeImage__play-icon-container___3qNQU {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.SafeImage__play-icon-container___3qNQU i {
  font-size: 90px;
}
.SafeImage__spinner-wrapper___3C0bh {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #F1F1F1;
  z-index: 2;
}
.SafeImage__with-error___1zt44 {
  background-size: 40%;
  background-color: #F1F1F1;
  object-fit: scale-down;
}
.SafeImage__overlay___1qcz6 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.SafeImage__gift-icon-lite___1qi1O {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
}
