.ChatImagesUploader__icon____yoDn {
  font-size: 2rem;
  margin-top: 0.8rem;
  color: #767676;
  cursor: pointer;
}
.ChatImagesUploader__icon____yoDn:not(.ChatImagesUploader__disabled___22DKb):hover {
  color: var(--primary);
}
.ChatImagesUploader__icon____yoDn.ChatImagesUploader__disabled___22DKb {
  color: #F1F1F1;
  cursor: default;
}
