.AdvertiserChip__avatar___1Bw1C {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.AdvertiserChip__name___3Ysks {
  font-size: 1.3rem;
}
