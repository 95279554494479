.CampaignHeader__wrapper___32Ngm {
  display: flex;
  padding: 3rem 6rem;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 930px) {
  .CampaignHeader__wrapper___32Ngm {
    padding: 2rem;
  }
}
.CampaignHeader__overlay___1cpFK {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.9) 100%);
}
.CampaignHeader__info___3Na1o {
  width: 100%;
  margin-top: auto;
  color: #FFFFFF;
  display: grid;
  grid-gap: 2.2rem;
  align-items: center;
  grid-template-columns: auto 250px 128px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "avatar name stars child";
}
@media (max-width: 930px) {
  .CampaignHeader__info___3Na1o {
    grid-template-columns: auto 1fr 128px;
    grid-template-rows: auto 1fr;
    grid-template-areas: "avatar name stars" "child child child";
  }
}
@media screen and (max-width: 560px) {
  .CampaignHeader__info___3Na1o {
    height: 100%;
    margin-top: 0;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 1rem;
    grid-template-areas: "avatar name" "child child";
  }
}
.CampaignHeader__avatar___1zMKT {
  width: 8rem;
  height: 8rem;
  margin: 0 1rem;
  max-width: 8rem;
  border-radius: 8rem;
}
@media screen and (max-width: 560px) {
  .CampaignHeader__stars___3Jfwr {
    display: none;
  }
}
.CampaignHeader__logo___2-vZo {
  width: auto;
  height: 30px;
  margin-bottom: 16px;
}
@media screen and (max-width: 560px) {
  .CampaignHeader__logo___2-vZo {
    display: none;
  }
}
