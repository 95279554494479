.MobileAppBarUI__wrapper___31pt2 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 8.5rem;
  background-color: white;
  border-bottom: 3px solid var(--primary);
  z-index: 100;
  padding: 0 1.5rem 0 0rem;
  color: #1f2626;
  box-shadow: 0px 2px 11px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.MobileAppBarUI__close___2qI4q {
  height: 1.2rem;
  margin: 0 1.5rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.MobileAppBarUI__thumb___3euCV {
  width: 52px;
  height: 52px;
  margin-right: 1.5rem;
  border-radius: 4px;
}
.MobileAppBarUI__button___15D-5 {
  margin-left: auto;
  padding: 0.5rem 3rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 20px;
  background: var(--primary);
  color: white;
}
.MobileAppBarUI__button___15D-5:hover {
  color: white;
}
.MobileAppBarUI__text___ypKM2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 1.5rem;
}
