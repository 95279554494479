.SocialAvatar__list___blru4 {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  transform: translateY(-100%);
}
.SocialAvatar__story-border___yK2D_ {
  border: 1px solid #F64A4A;
  border-radius: 50%;
}
