.CampaignBriefLite__brief___2j1jP {
  width: 100%;
  padding: 16px;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.CampaignBriefLite__brief___2j1jP .CampaignBriefLite__title___3n3-x {
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  color: #1F2626;
  font-family: Proxima Nova;
}
.CampaignBriefLite__brief___2j1jP .CampaignBriefLite__subtitle___GWuNA {
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0 8px;
  line-height: normal;
  color: #767676;
  font-family: Proxima Nova;
}
.CampaignBriefLite__brief___2j1jP .CampaignBriefLite__text___RYHq7 {
  font-size: 14px;
  font-weight: 400;
  color: #1F2626;
  line-height: normal;
  font-family: Proxima Nova;
}
.CampaignBriefLite__brief___2j1jP .CampaignBriefLite__text--gray___Kr9L- {
  color: #767676;
}
.CampaignBriefLite__brief___2j1jP .CampaignBriefLite__hashtag___SA-ci {
  margin-right: 0.8rem;
}
.CampaignBriefLite__brief___2j1jP .CampaignBriefLite__button___2QL14 {
  padding: 0;
  font-weight: 600;
  margin: 0 0 0 16px;
}
.CampaignBriefLite__containerSoldOut___2ewj8 {
  height: 100%;
  display: flex;
  padding: 16px;
  text-align: center;
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.CampaignBriefLite__containerSoldOut___2ewj8 .CampaignBriefLite__disclaimerTitle___-ytB8 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #272727;
}
.CampaignBriefLite__containerSoldOut___2ewj8 .CampaignBriefLite__disclaimerTitle__login___3FJ7f {
  font-weight: 400;
}
.CampaignBriefLite__containerSoldOut___2ewj8 .CampaignBriefLite__loginTitle___qpkNd {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #272727;
}
.CampaignBriefLite__containerSoldOut___2ewj8 .CampaignBriefLite__subtitle___GWuNA {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #272727;
}
.CampaignBriefLite__accounts___EMpMV {
  font-size: 24px;
  color: #1F2626;
  margin: 2rem 0 2rem 0;
}
.CampaignBriefLite__addProfile___3zVFS {
  gap: 10px;
  display: flex;
  align-items: center;
}
.CampaignBriefLite__briefLiteInfo___35nRB {
  font-size: 12px;
  margin-bottom: 2rem;
  color: #767676;
}
@media (max-width: 800px) {
  .CampaignBriefLite__containerBrief___3v8Gt {
    padding: 1rem 0.8rem;
  }
}
