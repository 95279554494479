:root {
  --primary: #16D4E6;
  --primaryD20: #0d7e89;
  --primaryD15: #0f94a0;
  --primaryD10: #12a9b8;
  --primaryD05: #14bfcf;
  --primaryL60: #ffffff;
  --primaryL45: #e5fbfd;
  --primaryL05: #2bdaeb;
  --primaryF60: rgba(22, 212, 230, 0.6);
  --primaryF50: rgba(22, 212, 230, 0.5);
  --primaryF20: rgba(22, 212, 230, 0.2);
  --primaryF10: rgba(22, 212, 230, 0.1);
  --primaryF05: rgba(22, 212, 230, 0.05);
  --gradientEnd: #ace500;
  --blackShadow: rgba(0, 0, 0, 0.12);
  --unbanSelectableBorder: #6cd425;
  --unbanSelectableBackground: rgba(108, 212, 37, 0.15);
}
.AccountDataForm__alert___1wFCX {
  gap: 8px;
  width: 100%;
  padding: 8px;
  display: flex;
  margin-top: 16px;
  border-radius: 2px;
  color: #FEBE2D;
  border: 1px solid #FEBE2D;
}
.AccountDataForm__alert___1wFCX > img {
  width: auto;
}
.AccountDataForm__music-container___QYh2g {
  gap: 8px;
  margin: 0;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
@media (max-width: 640px) {
  .AccountDataForm__music-container___QYh2g {
    grid-template-columns: auto auto;
  }
}
.AccountDataForm__music-container___QYh2g > div {
  margin: 0;
  padding: 8px;
  display: flex;
  font-size: 12px;
  justify-content: center;
}
