.MediaCarousel__carousel-item___3mbR3 {
  height: 350px;
}
.MediaCarousel__arrow___HL4eu {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  padding: 2rem 2rem 1.1rem;
  cursor: pointer;
}
.MediaCarousel__arrow___HL4eu i {
  color: white;
  font-size: 2rem;
}
.MediaCarousel__right___3yCB- {
  right: 0;
}
.MediaCarousel__left___PB1kD {
  left: 0;
}
.MediaCarousel__dots___2YEhx {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}
.MediaCarousel__urlContainer___2I4FE {
  padding: 2rem;
}
