.SocialNetworksFormUI__dialog___n4H2K {
  border-radius: 4px;
  background-color: white;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__dialogContainer___a_EEj {
  padding: 15px;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__dialogContainer___a_EEj .SocialNetworksFormUI__unlinkContainer___1F_D1 {
  margin: 7rem 2rem;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #272727;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__dialogContainer___a_EEj .SocialNetworksFormUI__unlinkContainer_modal___1PLpS {
  margin: 0;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__dialogContainer___a_EEj .SocialNetworksFormUI__unlinkContainer___1F_D1 .SocialNetworksFormUI__unlinkTitle___1bA0r {
  margin-bottom: 4rem;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__dialogContainer___a_EEj .SocialNetworksFormUI__unlinkContainer___1F_D1 .SocialNetworksFormUI__unlinkName___3hqkd {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__dialogContainer___a_EEj .SocialNetworksFormUI__unlinkContainer___1F_D1 .SocialNetworksFormUI__unlinkWarning___3_GfC {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__containerAccount___1CCh_ {
  padding: 5.5rem;
}
.SocialNetworksFormUI__dialog_unlinkContainer___2iqPi {
  margin: 2rem 4rem !important;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__header___3szyO {
  display: flex;
  justify-content: center;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__buttonContainer___3PEvk {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  justify-content: end;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__buttonContainer_modal___YTg1p {
  justify-content: right;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__buttonContainer_unlink___Yar_9 {
  justify-content: center;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__buttonContainer___3PEvk .SocialNetworksFormUI__button___pGSSA {
  margin-top: 1.5em;
  width: 14rem;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__buttonContainer___3PEvk .SocialNetworksFormUI__buttonCancel___2yDjy {
  margin-top: 1.5em;
  width: 14rem;
  text-align: center;
  color: #767676;
  font-size: 14px;
  font-weight: 600;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__buttonUnlink___28f5P {
  margin-top: 1rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #F64A4A;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__labelContainer___3nuNT {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__labelContainer___3nuNT .SocialNetworksFormUI__asterisk___1olEf {
  padding-left: 0.5rem;
  color: #f64a4a;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__instruction___20P2A {
  font-size: 14px;
  font-weight: 400;
  color: #16d4e6;
  margin-left: 2.5rem;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__disclaimer___skOCf {
  color: #ffdc4c;
  margin-left: 0.9rem;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__errorContainer___3K1aw {
  min-height: 1.2em;
  max-height: 1.2em;
  margin-top: .3em;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__errorLabel___3WeCd {
  color: red;
  font-size: .8em;
  margin: 0;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__imgContainer___ehMf7 {
  display: flex;
  justify-content: center;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__imgContainer___ehMf7 .SocialNetworksFormUI__iconCreator___3-wRD {
  width: 10em;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr {
  display: block;
  box-shadow: none;
  border: 1px solid #C0C0C0;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 0.5rem 1.5rem;
  width: 100%;
  outline: none;
  font-weight: 400;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr:focus {
  border: 1px solid var(--primary);
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr::placeholder {
  color: #C0C0C0;
  font-weight: 400;
  opacity: 0.6;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr:-ms-input-placeholder {
  color: #C0C0C0;
  font-weight: 400;
  opacity: 0.6;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr::-ms-input-placeholder {
  color: #C0C0C0;
  font-weight: 400;
  opacity: 0.6;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr[type=number]::-webkit-inner-spin-button,
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input___1f6Rr[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input-active___1eRp3 {
  border: 1px solid var(--primary);
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input-error___2-zDQ {
  border: 1px solid #F85555;
}
.SocialNetworksFormUI__dialog___n4H2K .SocialNetworksFormUI__input-error___2-zDQ:focus {
  border: 1px solid #F85555;
}
.SocialNetworksFormUI__modal___lzW4C {
  min-width: 25vw;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.SocialNetworksFormUI__instaback___1aCV- {
  background-color: #e4405f;
}
.SocialNetworksFormUI__youtubeback___1lLCp {
  background-color: #ff0000;
}
.SocialNetworksFormUI__fbkback___3ph0U {
  background-color: #1778F2;
}
.SocialNetworksFormUI__formLabel___1DQKx {
  color: #767676;
  margin: .8em 0;
  font-weight: bold;
}
.SocialNetworksFormUI__headerIcon___3lfFp {
  width: 3em;
}
.SocialNetworksFormUI__paddin___1TQI- {
  padding-left: 6rem;
}
