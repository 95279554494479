.SearchInput__wrapper___1P8oT {
  border: 1px solid #C0C0C0;
  background: white;
  padding: 0.5rem 1.5rem;
  font-size: 1.6rem;
}
.SearchInput__wrapper___1P8oT button {
  box-shadow: none;
  border: none;
  margin-right: 1rem;
  height: 17px;
  outline: none;
  color: #767676;
  background-color: transparent;
}
.SearchInput__input___mWqyw {
  border: none;
  width: 100%;
  outline: none;
  background: white;
  padding-left: 0.5rem;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-appearance: none;
}
.SearchInput__input___mWqyw:focus {
  border: none;
}
.SearchInput__input___mWqyw::-webkit-input-placeholder {
  color: #C0C0C0;
}
.SearchInput__input___mWqyw::-moz-placeholder {
  color: #C0C0C0;
}
.SearchInput__input___mWqyw:-ms-input-placeholder {
  color: #C0C0C0;
}
.SearchInput__input___mWqyw:-moz-placeholder {
  color: #C0C0C0;
}
