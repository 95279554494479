.InvalidAccount__dialog___PtN3N {
  border-radius: 4px;
  position: relative;
  height: min-content !important;
  background-color: #FFFFFF;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__dialogContainer___FJkcY {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__header___3RhtU {
  display: flex;
  font-size: 16px;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__buttonContainer___3YxLA {
  display: flex;
  grid-gap: 2rem;
  align-items: center;
  justify-content: center;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__buttonContainer___3YxLA .InvalidAccount__button___1RPoX {
  width: 88px;
  margin: 3rem 0 1rem 0;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__buttonContainer___3YxLA .InvalidAccount__buttonCancel___1sTeg {
  font-size: 18px;
  font-weight: 600;
  margin-top: 0.5em;
  text-align: center;
  color: #767676;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__disclaimer___DexWK {
  font-size: 14px;
  text-align: center;
  color: #1F2626;
  margin: 0 1rem 0 1rem;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__icon___1HkHq {
  align-self: center;
}
.InvalidAccount__dialog___PtN3N .InvalidAccount__icon___1HkHq .InvalidAccount__iconWarning___1d9Kr {
  width: 76px;
  margin: 0 1rem 2rem 0;
  filter: hue-rotate(50deg) brightness(168%);
}
.InvalidAccount__dialogContent___2briN {
  display: flex;
  padding: 6rem 0;
  justify-content: center;
}
