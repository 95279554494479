.FillBar__chart___139KK {
  display: flex;
  min-width: 50%;
}
.FillBar__container___1XHId {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}
.FillBar__label___yEbXD {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 600;
}
.FillBar__percentage___1G_rD {
  font-size: 1.3rem;
  width: 25%;
}
.FillBar__progress___5anpV {
  background-color: var(--primary);
  border-radius: 8px;
  height: 8px;
}
.FillBar__total___IfiMC {
  width: 75%;
  background-color: #F1F1F1;
  border-radius: 8px;
  flex: 1;
  height: 8px;
  overflow: hidden;
}
