.SinglePlaceSelect__wrapper___2thRc {
  background-color: #FFFFFF;
  border: 1px solid #C0C0C0;
}
.SinglePlaceSelect__error___EBQGt {
  border: 1px solid #F85555;
}
.SinglePlaceSelect__input___1Z1r6 {
  border: none;
  padding: 0;
  background: transparent;
}
.SinglePlaceSelect__input___1Z1r6:focus {
  border: none;
}
.SinglePlaceSelect__item___1REGz {
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.SinglePlaceSelect__item___1REGz:hover {
  background: var(--primaryF20);
}
.SinglePlaceSelect__icon___Dr_mu {
  font-size: 1rem;
  color: #767676;
  margin-right: 1rem;
}
