.EasyLexContract__container___1eVZ4 {
  color: #767676;
  font-size: 12px;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__infoContainer___3Ym-U {
  padding: 24px;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__infoContainer___3Ym-U .EasyLexContract__mainTittle___7NgUl {
  margin-bottom: 16px;
  color: #272727;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__infoContainer___3Ym-U .EasyLexContract__instructionsLabel___1y_VV {
  margin-bottom: 10px;
  font-weight: 600;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__infoContainer___3Ym-U .EasyLexContract__instructions___1KQyI {
  font-size: 12px;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__buttonContainer___3vUU6 {
  width: 100%;
  height: 96px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__proccesButtonContainer___1BDsV {
  width: 100%;
  font-size: 14px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.EasyLexContract__container___1eVZ4 .EasyLexContract__proccesButtonContainer___1BDsV .EasyLexContract__cancelButton____PpNW {
  color: var(--primary);
}
