.ProductsPaymentAddressInput__lite___BDZhy {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 13px;
}
@media (max-width: 1025px) {
  .ProductsPaymentAddressInput__lite___BDZhy {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50vw;
  }
  .ProductsPaymentAddressInput__lite___BDZhy .ProductsPaymentAddressInput__button___1b_QW {
    font-size: 14px;
    margin: 1rem 4rem 0 0;
    width: 80px;
    height: 30px;
  }
}
