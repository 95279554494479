.Paginator__pagination___7THIj {
  list-style: none;
  padding: 0;
  display: inline-flex;
}
.Paginator__pagination___7THIj li {
  padding: .5em;
}
.Paginator__pagination___7THIj a {
  outline: none;
}
.Paginator__page___3L11K a {
  outline: none;
  color: #1F2626;
}
.Paginator__active___dHg9y a {
  color: #C0C0C0;
}
.Paginator__arrow-disabled___22slI div {
  background-color: #C0C0C0;
}
.Paginator__pagination-arrows___3v9Cu {
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #1F2626;
}
.Paginator__pagination-arrows___3v9Cu i {
  font-size: 1rem;
}
