.CampaignCard__container___2mwO6 {
  height: 22rem;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.CampaignCard__container___2mwO6:hover {
  box-shadow: 0 1rem 3.75rem rgba(0, 0, 0, 0.2) !important;
  transform: scale(1.025);
}
.CampaignCard__gradient___2__QX {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}
.CampaignCard__label___ECd1H {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 2rem;
  padding: 1rem 2rem;
  border-radius: 2.5rem 0 0 2.5rem;
  white-space: nowrap;
}
.CampaignCard__disabled___2aPvV {
  filter: grayscale(100%);
}
