.Dialog__overlay___1ATSZ {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(92, 101, 102, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
}
.Dialog__container___1gQ3w {
  max-height: 100vh;
}
.Dialog__header___nUrDv {
  padding: 2rem;
  min-height: 10rem;
  color: white;
  background: black;
}
.Dialog__header___nUrDv i {
  cursor: pointer;
  font-size: 2.5rem;
}
