.Accordion__header___lh57P {
  transition: box-shadow 0.2s;
  border-bottom: 1px solid #F1F1F1;
}
.Accordion__header___lh57P.Accordion__expanded___7NHpp {
  box-shadow: inset 3px 0 0 #1f2626;
}
.Accordion__header___lh57P:not(:first-child) {
  border-top: 1px solid #F1F1F1;
}
.Accordion__title___3SQAT {
  max-width: 90%;
}
.Accordion__icon___34VVG {
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.Accordion__content___1AFWE {
  transition: height 0.2s;
}
