.ParticipationLayout__grid___3BuV8 {
  margin: 0 auto;
  padding: 0 32px 32px;
  display: grid;
  grid-gap: 32px 24px;
  grid-template-columns: 1fr 407px;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas: "btitle btitle" "brief right";
}
.ParticipationLayout__grid__soldout___3ril2 {
  margin: 0;
}
@media screen and (max-width: 930px) {
  .ParticipationLayout__grid___3BuV8 {
    grid-template-areas: "btitle btitle" "brief right";
  }
}
@media screen and (max-width: 800px) {
  .ParticipationLayout__grid___3BuV8 {
    padding: 0 1rem 3rem;
    grid-template-columns: 100%;
    grid-template-areas: "btitle" "brief" "right";
  }
}
.ParticipationLayout__right___3CwC2 {
  grid-area: right;
}
@media screen and (max-width: 800px) {
  .ParticipationLayout__brief___2Bezm {
    padding: 0;
  }
}
