.PartnerFooterUI__footer___2bJvI {
  text-align: center;
  width: 100%;
  color: white;
}
.PartnerFooterUI__footer___2bJvI a {
  color: white;
}
.PartnerFooterUI__select-button___3oHci {
  color: white;
}
.PartnerFooterUI__select-button___3oHci i {
  font-size: inherit !important;
}
