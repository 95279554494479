.AudienceKeywordsUI__input___3TUWt {
  border: none;
  padding: 0;
  background: transparent;
}
.AudienceKeywordsUI__input___3TUWt:focus {
  border: none;
}
.AudienceKeywordsUI__search-item___3nZuL {
  cursor: pointer;
  padding: 1rem;
  color: #C0C0C0;
}
.AudienceKeywordsUI__search-item___3nZuL:hover {
  background-color: var(--primaryF10);
}
.AudienceKeywordsUI__selected-item___1_rNW i {
  visibility: hidden;
  font-size: 1.2rem;
}
.AudienceKeywordsUI__selected-item___1_rNW i:hover {
  color: var(--primary);
}
.AudienceKeywordsUI__selected-item___1_rNW:hover {
  background-color: #F1F1F1;
}
.AudienceKeywordsUI__selected-item___1_rNW:hover i {
  visibility: visible;
}
