.MoneyInput__wrapper___3Xxtq {
  border: 1px solid #C0C0C0;
  border-radius: 2px;
}
.MoneyInput__icon-box___TVPyD {
  padding: 0.8rem;
  background-color: #F1F1F1;
  line-height: 1.5;
  font-size: 1.5rem;
}
.MoneyInput__input___2HE-W {
  padding: 0.8rem;
  border: none;
  line-height: 1.5;
  font-size: 1.5rem;
  border-radius: 0;
  border-left: 1px solid #C0C0C0;
  border-right: 1px solid #C0C0C0;
}
.MoneyInput__input___2HE-W:focus {
  border-color: #C0C0C0 !important;
}
