:root {
  --primary: #16D4E6;
  --primaryD20: #0d7e89;
  --primaryD15: #0f94a0;
  --primaryD10: #12a9b8;
  --primaryD05: #14bfcf;
  --primaryL60: #ffffff;
  --primaryL45: #e5fbfd;
  --primaryL05: #2bdaeb;
  --primaryF60: rgba(22, 212, 230, 0.6);
  --primaryF50: rgba(22, 212, 230, 0.5);
  --primaryF20: rgba(22, 212, 230, 0.2);
  --primaryF10: rgba(22, 212, 230, 0.1);
  --primaryF05: rgba(22, 212, 230, 0.05);
  --gradientEnd: #ace500;
  --blackShadow: rgba(0, 0, 0, 0.12);
  --unbanSelectableBorder: #6cd425;
  --unbanSelectableBackground: rgba(108, 212, 37, 0.15);
}
.TagInputUI__input___1yNCM {
  padding-right: calc(7rem + 3px);
}
.TagInputUI__button___1R9Ft {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 7rem;
  height: 3rem;
  padding: 0.8rem;
  border-radius: 0.25rem;
  color: #FAFAFA;
  background-color: #C0C0C0;
  font-size: .7em;
}
.TagInputUI__button___1R9Ft:hover:not(:disabled) {
  background-color: #767676;
}
.TagInputUI__tags-container___3NwHO {
  display: flex;
  flex-wrap: wrap;
}
.TagInputUI__tag___1ppYr {
  display: inline-flex;
  align-items: center;
  margin: 0 1rem 1rem 0;
  padding: 0.5rem 1rem;
  background-color: #FAFAFA;
  border: 1px solid #C0C0C0;
  color: #767676;
  cursor: pointer;
  border-radius: 0.5rem;
}
.TagInputUI__tag___1ppYr:hover {
  background: #F1F1F1;
}
.TagInputUI__tag___1ppYr i {
  color: #1f2626;
  font-size: 1.2rem;
}
