.PowerByVoxfeed__label___1VzD5 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.PowerByVoxfeed__label___1VzD5 img {
  display: inline-block;
  width: auto;
  height: 24px;
  margin-left: 5px;
}
