.Main__container___3DPk9 {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 90vw;
  height: 0;
  z-index: 999;
}
.Main__row___2QWlc {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
}
.Main__extra-chats___10fRf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  background-color: #1f2626;
  color: #FAFAFA;
  cursor: pointer;
}
.Main__extra-chats___10fRf i {
  font-size: 2.5rem;
  margin-top: 1.2rem;
}
.Main__extra-chat-row___3X4D_ {
  width: 100%;
  cursor: pointer;
  padding: 1rem;
}
.Main__extra-chat-row___3X4D_:hover {
  background-color: #C0C0C0;
}
