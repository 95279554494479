.ReliabilityBar__score-bar___1SUK2 {
  height: 0.5rem;
  border-radius: 100px;
}
.ReliabilityBar__score-bar___1SUK2 .ReliabilityBar__positive-score___BXm1x {
  box-shadow: inset -4px 0 0 0 #FFFFFF;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 100px;
  transition: 0.2s all;
}
