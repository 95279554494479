.UserPhoneEdition__container___1mSmv {
  overflow: hidden;
  width: 100%;
  align-items: center;
}
.UserPhoneEdition__container___1mSmv input::-webkit-input-placeholder {
  padding-left: 2rem;
}
.UserPhoneEdition__container___1mSmv input::-moz-placeholder {
  padding-left: 2rem;
}
.UserPhoneEdition__prefix-input___2qBb0 {
  line-height: 1;
  position: absolute;
  border-style: none;
}
.UserPhoneEdition__prefix-input___2qBb0:after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 0;
  border-right: 1px solid #d7d9d9;
}
