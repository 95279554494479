.BrandSelector__container___bo56e {
  height: 55vh;
  background-color: #FAFAFA;
}
.BrandSelector__title___u7PlT {
  background-color: #FFFFFF;
  border-bottom: 1px solid #F1F1F1;
  height: 46px;
}
.BrandSelector__searcher___eugA2 i {
  left: 0.5rem !important;
  font-size: 1.5rem !important;
}
.BrandSelector__searcher___eugA2 input {
  padding: 0 0 0 3rem !important;
}
.BrandSelector__searcher___eugA2 input::-webkit-input-placeholder {
  font-size: 1.5rem !important;
  font-style: normal !important;
}
.BrandSelector__searcher___eugA2 input::-moz-placeholder {
  font-size: 1.5rem !important;
  font-style: normal !important;
}
.BrandSelector__brand___3kVsH {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  border: 1px solid #F1F1F1;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 1px #D2D4D4;
  margin: 1rem 0;
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s;
}
.BrandSelector__brand___3kVsH:hover {
  background-color: #F1F1F1;
}
