.VideoPreview__icon___17CiO {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 1;
  color: white;
  font-size: 8rem;
  cursor: pointer;
}
