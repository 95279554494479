.InfluencerTypeSelect__select___2FYUW {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}
.InfluencerTypeSelect__item___2D_GJ {
  width: 100%;
  padding: 1em;
  color: #767676;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F1F1F1;
}
.InfluencerTypeSelect__item___2D_GJ:hover:not(.InfluencerTypeSelect__activeItem___Ka-4f) {
  background-color: #FAFAFA;
}
.InfluencerTypeSelect__activeItem___Ka-4f {
  background-color: var(--primaryF10);
  color: #767676;
}
.InfluencerTypeSelect__activeItem___Ka-4f:hover {
  background-color: var(--primaryF05);
}
