.CampaignBrief__img___2T4TE {
  height: 22.5rem;
  width: calc(50% - 1rem);
  margin-bottom: 2rem;
  border-radius: 4px;
  overflow: hidden;
}
.CampaignBrief__single___2rFYR {
  width: 100%;
}
.CampaignBrief__boostContainer___3eomB {
  margin: 12px 0 8px;
}
.CampaignBrief__boostCode___1m5_W {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #272727;
}
.CampaignBrief__briefInfo___3cyJe {
  color: #767676;
}
