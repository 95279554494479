.OrganizationMenuUI__list___1Zxu7 {
  max-height: 200px;
  overflow-y: auto;
}
.OrganizationMenuUI__row___1RDuF {
  color: black;
  padding: 2rem 3rem;
}
.OrganizationMenuUI__row___1RDuF:hover {
  color: black;
  background-color: #F1F1F1;
}
.OrganizationMenuUI__button___1nT4F {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.75rem;
  padding: 1rem;
  margin: 0 2rem;
  cursor: pointer;
  position: relative;
}
.OrganizationMenuUI__button___1nT4F:hover {
  background: rgba(255, 255, 255, 0.1);
}
.OrganizationMenuUI__button___1nT4F i {
  margin: 0 1rem;
}
.OrganizationMenuUI__dot___3oHuI {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #F85555;
  position: absolute;
  left: 1rem;
}
.OrganizationMenuUI__create-link___3WPeh {
  padding: 2rem 3rem;
  border-bottom: 1px solid #C0C0C0;
  min-width: 30rem;
}
.OrganizationMenuUI__pending___2jN8c {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #F85555;
  position: absolute;
  right: 10%;
  top: 15%;
}
