.ParticipationModalLite__dialog___1BHSr {
  border-radius: 4px;
  background-color: white;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__dialogContainer___1DKUK {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__input___3xdfT {
  padding: 0;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__header___1jkF1 {
  display: flex;
  font-size: 16px;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__buttonContainer___3_IMn {
  display: flex;
  grid-gap: 2rem;
  align-items: center;
  justify-content: flex-end;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__buttonContainer___3_IMn .ParticipationModalLite__button___P85js {
  margin-top: 3rem;
  width: 14rem;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__errorContainer___2uQtI {
  margin-top: .3em;
  min-height: 1.2em;
  max-height: 1.2em;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__disclaimer___2Bw-p {
  gap: 8px;
  width: 100%;
  padding: 8px;
  display: flex;
  color: #FEBE2D;
  margin-top: 16px;
  border-radius: 2px;
  border: 1px solid #FEBE2D;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__disclaimer___2Bw-p > img {
  width: auto;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__errorLabel___3Swta {
  margin: 0;
  color: red;
  font-size: .8em;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__labelContainer___22bYX {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  margin: 2px 8px 8px 0;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__labelContainer___22bYX .ParticipationModalLite__asterisk___2z49Y {
  color: #f64a4a;
  padding-left: 0.5rem;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__media___30H-h {
  width: 100%;
  border-radius: 4px;
  margin-right: 1rem;
}
.ParticipationModalLite__dialog___1BHSr .ParticipationModalLite__thumbnail___2ks9o {
  width: 100%;
  height: 16rem;
  overflow: hidden;
  border-radius: 2px;
  border: solid 1px #16d4e6;
  background-color: rgba(22, 212, 230, 0.15) !important;
}
