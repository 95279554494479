.ChatsList__filters___2aGCF {
  height: 8rem;
  padding-left: 1.5rem;
}
.ChatsList__arrow___3QtG0 {
  cursor: pointer;
  height: 2rem;
  margin-left: 1rem;
}
.ChatsList__arrow___3QtG0.ChatsList__disabled___Q8_3j {
  color: #C0C0C0;
  cursor: default;
}
.ChatsList__chats-container___2BBFN {
  border: 1px solid #F1F1F1;
  border-left-style: none;
  height: calc(100% - 8rem);
}
.ChatsList__chats___K1CDz {
  max-height: calc(100% - 8rem);
}
