.PostBanner__post-banner___ADaBm {
  width: 100%;
}
.PostBanner__post-banner___ADaBm .PostBanner__link-section___3yu-b {
  display: flex;
  margin-bottom: 8px;
  justify-content: flex-end;
}
.PostBanner__post-banner___ADaBm .PostBanner__banner___3CoU- {
  height: 350px;
  text-align: center;
}
.PostBanner__post-banner___ADaBm .PostBanner__banner___3CoU- .PostBanner__post___1hf7x {
  height: 350px;
  width: 100%;
  border: none;
}
.PostBanner__circle___3fZR7 {
  width: 2rem;
  height: 2rem;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  margin: 0 0 7px 1rem;
  justify-content: center;
  background-color: #16d4e6;
}
.PostBanner__circle__help___1dTIW {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}
