.BrandChatsList__row___2wP2v {
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  min-height: 13rem;
}
.BrandChatsList__row___2wP2v:hover:not(.BrandChatsList__active___1T7G_) {
  background-color: #FAFAFA;
}
.BrandChatsList__row___2wP2v:not(:last-child) {
  border-bottom: 1px solid #F1F1F1;
}
.BrandChatsList__active___1T7G_ {
  cursor: default;
  background-color: var(--primaryF20);
}
