.BudgetCapacityCard__card___3qSmk {
  border-width: 1px;
  border-style: solid;
  transition: all 0.2s;
  cursor: default;
}
.BudgetCapacityCard__hover___3jORL:hover {
  transform: scale(1.02);
}
.BudgetCapacityCard__low-border___1dAdP {
  border-color: #F85555;
}
.BudgetCapacityCard__low-border___1dAdP.BudgetCapacityCard__hover___3jORL:hover {
  box-shadow: 0 2px 4px 0 rgba(247, 85, 85, 0.3), 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.BudgetCapacityCard__medium-border___1tosk {
  border-color: #F8CD1C;
}
.BudgetCapacityCard__medium-border___1tosk.BudgetCapacityCard__hover___3jORL:hover {
  box-shadow: 0 4px 8px 0 rgba(250, 214, 64, 0.29);
}
.BudgetCapacityCard__high-border___Re3yF {
  border-color: #61CB19;
}
.BudgetCapacityCard__high-border___Re3yF.BudgetCapacityCard__hover___3jORL:hover {
  box-shadow: 0 4px 8px 0 rgba(97, 203, 25, 0.2);
}
.BudgetCapacityCard__disabled___1AWWj {
  border-color: #C0C0C0;
}
