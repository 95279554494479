.FadeComponent__enter___2V8JA {
  opacity: 0;
}
.FadeComponent__enter-active___1jvcN {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.FadeComponent__leave___16PWF {
  position: absolute;
  opacity: 1;
}
.FadeComponent__leave-active___1UVes {
  opacity: 0;
}
