.Tab__container___23bvI {
  display: inline-flex;
  color: #767676;
  cursor: pointer;
}
.Tab__active___2kH2O {
  color: var(--primary) !important;
  border-bottom: 2px solid var(--primary);
}
.Tab__badge___33Ids {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  border-radius: 2.5rem;
  background-color: #F85555;
  font-size: 1.2rem;
  font-weight: 400;
  color: #FAFAFA;
  padding: 0 1rem;
}
.Tab__gray-badge___58DqR {
  background-color: #767676;
}
