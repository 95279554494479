:root {
  --primary: #16D4E6;
  --primaryD20: #0d7e89;
  --primaryD15: #0f94a0;
  --primaryD10: #12a9b8;
  --primaryD05: #14bfcf;
  --primaryL60: #ffffff;
  --primaryL45: #e5fbfd;
  --primaryL05: #2bdaeb;
  --primaryF60: rgba(22, 212, 230, 0.6);
  --primaryF50: rgba(22, 212, 230, 0.5);
  --primaryF20: rgba(22, 212, 230, 0.2);
  --primaryF10: rgba(22, 212, 230, 0.1);
  --primaryF05: rgba(22, 212, 230, 0.05);
  --gradientEnd: #ace500;
  --blackShadow: rgba(0, 0, 0, 0.12);
  --unbanSelectableBorder: #6cd425;
  --unbanSelectableBackground: rgba(108, 212, 37, 0.15);
}
.PinkDisclaimerDialog__dialog___2zUzv {
  width: 60vw;
  overflow: hidden;
  border-radius: 4px;
  background-color: #ffffff;
}
.PinkDisclaimerDialog__warning-box___1ekdf {
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #F8CD1C;
  background: rgba(248, 205, 28, 0.1);
}
.PinkDisclaimerDialog__warning-box___1ekdf i {
  font-size: 5rem;
  color: #F8CD1C;
}
.PinkDisclaimerDialog__warning-box__top___2Y2bZ {
  margin-top: 2rem;
}
.PinkDisclaimerDialog__warning-box__top--lite___dfgdR {
  width: 87%;
  float: right;
  margin-top: 4.5rem;
  background-color: #ffffff;
}
.PinkDisclaimerDialog__warning-box__msg___3lbrF {
  display: flex;
  align-items: flex-start;
}
.PinkDisclaimerDialog__warning-box__msg___3lbrF > img {
  margin-right: 1rem;
  filter: hue-rotate(50deg) brightness(168%);
}
.PinkDisclaimerDialog__warning-box__msg___3lbrF > span {
  font-size: 12px;
}
.PinkDisclaimerDialog__list___9Lw7u {
  padding: 2rem;
}
.PinkDisclaimerDialog__list___9Lw7u li {
  padding: 0.5rem;
  font-size: 2.2rem;
}
.PinkDisclaimerDialog__footer___2SxBM {
  margin-top: 8rem;
}
