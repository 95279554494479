.FilterWrapper__wrapper___2olBY {
  border-bottom: 1px solid #F1F1F1;
  user-select: none;
  background-color: white;
}
.FilterWrapper__title___1GMZB {
  color: #767676;
  margin: 0.8rem 0;
  cursor: pointer;
}
