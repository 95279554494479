.PublicCampaignHeaderUI__badges___34dqS {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 930px) {
  .PublicCampaignHeaderUI__badges___34dqS {
    grid-area: badges;
  }
}
.PublicCampaignHeaderUI__badge___3KY5j {
  margin: 0 1rem 0 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 0 0 0.5rem;
  flex-shrink: 1;
}
.PublicCampaignHeaderUI__badge___3KY5j:last-child {
  margin: 0;
}
.PublicCampaignHeaderUI__participate___zB85d {
  font-size: 1.8rem;
  text-align: right;
  margin-right: 2rem;
}
@media (max-width: 930px) {
  .PublicCampaignHeaderUI__participate___zB85d {
    text-align: left;
    grid-area: participate;
  }
}
