.VoxFeedFeedbackForm__button___3Vctn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
}
.VoxFeedFeedbackForm__button___3Vctn i {
  font-size: 2.5rem !important;
}
.VoxFeedFeedbackForm__button___3Vctn span {
  display: none;
  height: 16px;
}
.VoxFeedFeedbackForm__button___3Vctn:hover {
  padding-right: 0rem;
}
.VoxFeedFeedbackForm__button___3Vctn:hover span {
  display: initial;
}
.VoxFeedFeedbackForm__button___3Vctn.VoxFeedFeedbackForm__selected___2XwBN {
  background-color: rgba(22, 212, 230, 0.1) !important;
  border: 1px solid rgba(22, 212, 230, 0.1) !important;
  padding-right: 0rem;
}
.VoxFeedFeedbackForm__button___3Vctn.VoxFeedFeedbackForm__selected___2XwBN:hover {
  border: 1px solid rgba(22, 212, 230, 0.1) !important;
}
.VoxFeedFeedbackForm__button___3Vctn.VoxFeedFeedbackForm__selected___2XwBN span {
  display: initial;
}
