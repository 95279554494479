.PhoneVerificationWidget__container___27_M9 {
  overflow: hidden;
  width: 100%;
  font-size: 1.8rem;
  align-items: center;
}
.PhoneVerificationWidget__container___27_M9 input {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1;
  border-style: none;
  border-bottom: 1px solid #767676;
}
.PhoneVerificationWidget__container___27_M9 input:focus {
  border-color: #C0C0C0 !important;
}
.PhoneVerificationWidget__container___27_M9 input:focus::-webkit-input-placeholder {
  color: #C0C0C0 !important;
}
.PhoneVerificationWidget__container___27_M9 input:focus::-moz-placeholder {
  color: #C0C0C0 !important;
}
