.OrderControl__icon___2mB41 {
  font-size: 1.8rem;
}
.OrderControl__span___2hdVU {
  cursor: pointer;
  white-space: nowrap;
  margin-right: 1.5rem;
  line-height: 1rem;
}
.OrderControl__selected___3N05P {
  color: var(--primary);
}
.OrderControl__select___rhnCP {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #C0C0C0;
  color: #767676;
}
