.AudienceFilterHeader__popup___1CFnM {
  position: absolute;
  width: calc(100% - 24px);
  padding: 8px 8px;
  z-index: 10;
  background-color: white;
  border: 1px solid #C0C0C0;
  left: 12px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 1px 0, rgba(0, 0, 0, 0.08) 0 3px 8px;
}
.AudienceFilterHeader__popup___1CFnM a {
  display: block;
  text-align: right;
}
