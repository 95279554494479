.SocialNetworkCard__networkContainer___LJtVg {
  display: flex;
  flex-direction: row;
  height: 80px;
  margin: 0 0 16px;
  padding: 8px 18px 8px 8px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(97, 203, 25, 0.21);
  border: solid 1px #61cb19;
  background-color: #fff;
}
.SocialNetworkCard__networkContainer___LJtVg .SocialNetworkCard__avatar___3K8r0 {
  width: 20%;
}
.SocialNetworkCard__networkContainer___LJtVg .SocialNetworkCard__containerInfo___1G2pC {
  margin-left: 2.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.SocialNetworkCard__networkContainer___LJtVg .SocialNetworkCard__containerInfo___1G2pC .SocialNetworkCard__userName___3Sz4Z {
  font-size: 15px;
  font-weight: 600;
  color: #505050;
}
.SocialNetworkCard__networkContainer___LJtVg .SocialNetworkCard__containerInfo___1G2pC .SocialNetworkCard__follows___1kY7v {
  font-size: 13px;
  color: rgba(80, 80, 80, 0.7);
}
.SocialNetworkCard__networkContainer___LJtVg .SocialNetworkCard__containerInfo___1G2pC .SocialNetworkCard__button___3WQce {
  font-size: 14px;
  height: max-content;
  padding: 1.5rem 4.3rem;
}
