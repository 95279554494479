.Badge__container___1ZyEf {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.2rem;
  height: 2.2rem;
  border-radius: 2.2rem;
  padding: 0 0.75rem;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #FFFFFF;
  background-color: #F85555;
}
