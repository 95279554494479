.InviteDetailHeaderUI__grid___2VfwP {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  justify-items: end;
  grid-template-areas: "price user" ".     time";
}
@media (max-width: 930px) {
  .InviteDetailHeaderUI__grid___2VfwP {
    grid-template-columns: auto auto auto auto;
    justify-items: start;
    grid-template-areas: "user price time";
  }
}
@media screen and (max-width: 560px) {
  .InviteDetailHeaderUI__grid___2VfwP {
    grid-template-columns: auto auto;
    justify-items: start;
    grid-template-areas: "user price" ".    time";
  }
}
.InviteDetailHeaderUI__payment-container___ZNfoT {
  cursor: pointer;
}
.InviteDetailHeaderUI__payment-container___ZNfoT h3:hover {
  text-decoration: underline;
}
