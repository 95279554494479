.Item__item___1cODS {
  cursor: pointer;
  padding: 1rem;
}
.Item__item___1cODS:hover {
  background-color: var(--primaryF10);
}
.Item__item___1cODS span {
  color: #C0C0C0;
}
