.Titles__container___27fJN {
  gap: 10px;
  display: flex;
  margin: 21px 16px 0 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.Titles__container___27fJN .Titles__item___2FKi3 {
  gap: 8px;
  display: flex;
  align-items: center;
}
.Titles__container___27fJN .Titles__item___2FKi3 > img {
  width: 16px;
}
.Titles__container___27fJN .Titles__item___2FKi3 > p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  color: #272727;
  font-family: Proxima Nova;
}
@media screen and (max-width: 1024px) {
  .Titles__container___27fJN {
    display: none;
  }
}
