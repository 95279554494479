.BrandsSelect__select-item___2vYVj {
  padding: 1rem;
  border-top: 1px solid #C0C0C0;
  cursor: pointer;
}
.BrandsSelect__select-value___158xy {
  padding: 0;
  border: none;
  flex-grow: 1;
}
.BrandsSelect__input___2GYTR {
  flex-grow: 1;
  padding: 0;
  border: none;
  background: transparent;
}
.BrandsSelect__input___2GYTR:focus {
  border: none;
}
.BrandsSelect__icon___2_AC6 {
  font-size: 1rem;
  padding: 0 1rem;
}
