.InterestLabel__iconWrapper___37eiK {
  flex-shrink: 0;
  border-radius: 100%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
}
.InterestLabel__icon___3QxVI {
  margin-top: 0.5rem;
  font-size: 1.75rem;
  color: #FAFAFA;
}
.InterestLabel__label___2hLOZ {
  font-weight: 300;
  margin-left: 0.5em;
  font-size: 1.2em;
}
