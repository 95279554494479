.PublicCampaignCardLite__container___2Fgvg {
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 22rem;
}
.PublicCampaignCardLite__container___2Fgvg:hover {
  box-shadow: 0 1rem 3.75rem rgba(0, 0, 0, 0.2) !important;
  transform: scale(1.025);
}
.PublicCampaignCardLite__vox___1qY6R {
  width: 50rem;
  height: 35rem;
  display: grid;
  grid-template-rows: 8fr 1fr;
  padding: 0 !important;
  margin: 0 3rem 6rem 0!important;
}
.PublicCampaignCardLite__vox___1qY6R .PublicCampaignCardLite__image___3JQ-Z {
  padding: 2rem 2rem;
}
.PublicCampaignCardLite__voxBadges___3BSIl {
  padding: 0;
  margin: 0;
}
.PublicCampaignCardLite__postContainer___3-XL7 {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1.5rem 0 2rem;
  justify-content: space-between;
  background-color: #f2f2f2;
  color: #767676;
}
