.YoutubeVideoPreview__container___WqPY2 {
  height: 0;
  padding-top: 25px;
  position: relative;
  padding-bottom: 56.25%;
}
.YoutubeVideoPreview__container--small___21S0Z {
  padding: 0;
  height: auto;
  position: static;
}
.YoutubeVideoPreview__iframe___3SG5N {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.YoutubeVideoPreview__iframe--small___2Faeb {
  height: 200px;
  position: static;
}
