.BrandOption__avatar___1JS0H {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}
.BrandOption__name___KOFwB {
  font-size: 1.3rem;
}
