.ProductPopoverUI__popover___2Vubj {
  width: 232px;
  height: 184px;
  border-radius: 3px;
  overflow: hidden;
}
.ProductPopoverUI__info___Y17US {
  position: absolute;
  width: 90%;
  top: 6px;
  color: white;
  z-index: 10;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 1rem;
}
.ProductPopoverUI__info___Y17US p {
  font-size: 1.6rem;
}
.ProductPopoverUI__arrow___1Hx4C {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  padding: 2rem 2rem 1.1rem;
  cursor: pointer;
}
.ProductPopoverUI__arrow___1Hx4C i {
  color: white;
  font-size: 2rem;
}
.ProductPopoverUI__right___1tX28 {
  right: 0;
}
.ProductPopoverUI__left___14075 {
  left: 0;
}
.ProductPopoverUI__close___3EDyc {
  position: absolute;
  top: 16px;
  right: 8px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
  color: white;
  z-index: 10;
}
.ProductPopoverUI__address___11Rvi {
  position: absolute;
  bottom: 0;
  color: white;
  font-weight: 600;
  padding: 1rem;
  z-index: 10;
  width: 100%;
}
.ProductPopoverUI__overlay___2eK9y {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  z-index: 1;
}
