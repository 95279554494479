.PartnerFormUI__wrapper___2kS_2 {
  width: 100vw;
  min-height: 100vh;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1f2626;
}
.PartnerFormUI__card___fCo6o {
  padding: 0 3.4rem 3.4rem;
  background-color: white;
  border-radius: 4px;
  width: 384px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
.PartnerFormUI__logo___2uBqI {
  width: 100%;
  max-width: 230px;
  margin: 2rem auto;
  height: 112px;
}
.PartnerFormUI__footer___1EALy {
  width: 384px;
}
