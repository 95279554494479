.Menubar__menu___2LJyw {
  position: static;
  top: 0;
  left: 0;
  height: 7rem;
  width: 100%;
  padding: 0 5.5rem;
  z-index: 9999;
}
.Menubar__menu___2LJyw .Menubar__help___2WWOR {
  background-color: #FFFFFF;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  font-size: 1.75rem;
}
.Menubar__menu___2LJyw .Menubar__help___2WWOR i {
  color: #1f2626;
}
.Menubar__menu___2LJyw .Menubar__help___2WWOR:hover {
  background-color: #C0C0C0;
}
.Menubar__menu___2LJyw .Menubar__help___2WWOR:hover i {
  color: #FAFAFA;
}
.Menubar__menu___2LJyw .Menubar__dropdown___1aNWH {
  right: 0rem;
  width: 25rem;
}
.Menubar__menu___2LJyw .Menubar__user-menu-option___3dLtG {
  cursor: pointer;
}
.Menubar__menu___2LJyw .Menubar__user-menu-option___3dLtG:hover {
  background-color: #F1F1F1;
}
.Menubar__menu___2LJyw .Menubar__link___tMOow {
  width: 100%;
}
.Menubar__menu___2LJyw .Menubar__link___tMOow:hover {
  color: #1F2626;
}
.Menubar__menu___2LJyw .Menubar__icon___2mmBc {
  font-size: 2rem;
  margin-right: 1rem;
}
.Menubar__menu___2LJyw .Menubar__option___1ZxW6 {
  font-weight: 400;
  color: #FAFAFA;
  cursor: pointer;
  height: 100%;
  padding: 1rem;
}
.Menubar__menu___2LJyw .Menubar__option___1ZxW6.Menubar__active___3FalE {
  color: var(--primary);
}
.Menubar__menu___2LJyw .Menubar__option___1ZxW6 {
  font-weight: 400;
  color: #FAFAFA;
  cursor: pointer;
  height: 100%;
  padding: 1rem;
}
.Menubar__menu___2LJyw .Menubar__option___1ZxW6:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.Menubar__menu___2LJyw .Menubar__option___1ZxW6:hover:not(.Menubar__active___3FalE) {
  background-color: rgba(255, 255, 255, 0.1);
  color: #F1F1F1;
}
.Menubar__header___k9IdL {
  padding: 0 5.5rem;
}
.Menubar__overlay___1dcM4 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.9) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.Menubar__intercom-launcher___3-ybH {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  background-color: var(--primary);
}
.Menubar__intercom-launcher___3-ybH:hover {
  background-color: var(--primaryD10);
}
