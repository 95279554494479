.FilterHeaderTags__tag___1GZWc {
  display: flex;
  align-items: center;
  color: white;
  background-color: #767676;
  padding: 0.3rem 1.4rem;
  margin: 0 0.5rem 1rem 0;
  border-radius: 18px;
  cursor: pointer;
}
.FilterHeaderTags__tag___1GZWc span {
  display: block;
  height: 2.5rem;
  margin-left: 1rem;
}
.FilterHeaderTags__tag___1GZWc i {
  font-size: 1.2rem;
}
.FilterHeaderTags__title___1DRWR {
  border-bottom: 1px solid #C0C0C0;
}
