.LimitationList__capabilitiesTable___2wbAa {
  padding-left: 15px;
  margin-bottom: 16px;
  margin-top: 20px;
}
.LimitationList__capabilitiesTable___2wbAa li {
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #767676;
}
.LimitationList__limitationsTable___1TOco {
  padding-left: 15px;
  font-weight: bold;
}
.LimitationList__limitationsTable___1TOco li {
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #767676;
}
.LimitationList__accordions___2y1u1 {
  height: calc(100% - 12.75rem);
}
