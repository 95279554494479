.ChatBubble__bubble___2bbur {
  max-width: 66%;
  min-width: 17rem;
  padding: 2rem;
  border-radius: 1rem;
  white-space: pre-line;
  word-break: break-word;
  animation-duration: 0.3s;
}
.ChatBubble__bubble___2bbur.ChatBubble__with-images___2hBHo {
  width: 66%;
}
.ChatBubble__left___3zHlg {
  max-width: 66%;
  min-width: 17rem;
  padding: 2rem;
  border-radius: 1rem;
  white-space: pre-line;
  word-break: break-word;
  animation-duration: 0.3s;
  background-color: #F1F1F1;
}
.ChatBubble__left___3zHlg.ChatBubble__with-images___2hBHo {
  width: 66%;
}
.ChatBubble__left___3zHlg.ChatBubble__animate___2ZaDD {
  animation-name: ChatBubble__slide-in-left___50gbn;
}
.ChatBubble__tail-left___3-Rot {
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 0;
  height: 0;
  border-right: 2rem solid transparent;
  border-left: 0 solid transparent;
  border-top: 2rem solid #F1F1F1;
  animation-duration: 0.3s;
}
.ChatBubble__tail-left___3-Rot.ChatBubble__animate___2ZaDD {
  animation-name: ChatBubble__slide-in-left___50gbn;
}
.ChatBubble__right___3GtyF {
  max-width: 66%;
  min-width: 17rem;
  padding: 2rem;
  border-radius: 1rem;
  white-space: pre-line;
  word-break: break-word;
  animation-duration: 0.3s;
  background-color: var(--primaryL45);
}
.ChatBubble__right___3GtyF.ChatBubble__with-images___2hBHo {
  width: 66%;
}
.ChatBubble__right___3GtyF.ChatBubble__animate___2ZaDD {
  animation-name: ChatBubble__slide-in-right___2XvSv;
}
.ChatBubble__tail-right___gZ9e1 {
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: 0;
  height: 0;
  border-left: 2rem solid transparent;
  border-right: 0 solid transparent;
  border-top: 2rem solid var(--primaryL45);
  animation-duration: 0.3s;
}
.ChatBubble__tail-right___gZ9e1.ChatBubble__animate___2ZaDD {
  animation-name: ChatBubble__slide-in-right___2XvSv;
}
@keyframes ChatBubble__slide-in-left___50gbn {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes ChatBubble__slide-in-right___2XvSv {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.ChatBubble__date___2JKq2,
.ChatBubble__email___1moX8 {
  font-size: 1.2rem;
}
.ChatBubble__username___A7Cou {
  font-size: 1.3rem;
}
.ChatBubble__username___A7Cou,
.ChatBubble__email___1moX8 {
  max-width: 90%;
}
