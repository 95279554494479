.RenderBadges__img___3Elis {
  bottom: -10rem;
  display: flex;
  justify-content: flex-end;
}
.RenderBadges__imgVox___2ulhQ {
  display: flex;
  justify-content: flex-start;
  bottom: 0;
}
.RenderBadges__bcontainer___1bLTA {
  display: flex;
  flex-direction: row;
  bottom: -10rem;
  justify-content: flex-end;
}
.RenderBadges__bcontainer___1bLTA .RenderBadges__badge___1gsov {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-right: 1rem;
}
.RenderBadges__label___2g4nT {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 2rem;
  padding: 1rem 2rem;
  border-radius: 2.5rem 0 0 2.5rem;
  white-space: nowrap;
}
.RenderBadges__active___dC9jf {
  color: black !important;
  margin-left: 1rem;
  font-size: 14px;
  font-weight: 400;
}
.RenderBadges__counting___1YbVX {
  color: white;
  margin-left: 0.5rem;
}
.RenderBadges__voxContainer___14U5G {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.RenderBadges__voxContainer___14U5G .RenderBadges__rewards___3SGT7 {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
}
.RenderBadges__voxContainer___14U5G .RenderBadges__rewards___3SGT7 .RenderBadges__currency___15JXy {
  margin-right: 1rem;
  font-weight: 400;
}
.RenderBadges__terminated___Ft7nz {
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: #767676;
  font-size: 14px;
  font-weight: 400;
  padding-left: 2rem;
}
.RenderBadges__terminated___Ft7nz .RenderBadges__date___2v00y {
  margin-right: 1rem;
}
