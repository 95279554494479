.ProfileType__info___1OWAl {
  padding: 24px;
}
.ProfileType__info__text___1bwxa {
  font-size: 14px;
}
.ProfileType__info__text___1bwxa a {
  font-weight: bold;
}
.ProfileType__info__cards___1bFfK {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: center;
}
.ProfileType__info__content-buttons___1rhDD {
  padding: 24px;
}
.ProfileType__info__buttons___7IMib {
  border-radius: 2px;
  padding: 1.3rem 3.5rem;
}
