.Spinner__sk-circle___3oAVZ {
  width: 40px;
  height: 40px;
  position: relative;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-child___1T68T {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-child___1T68T:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #C0C0C0;
  border-radius: 100%;
  animation: Spinner__sk-circleBounceDelay___3v8TE 1.2s infinite ease-in-out both;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle2___3FIeM {
  transform: rotate(30deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle3___qZW7t {
  transform: rotate(60deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle4___zb7MT {
  transform: rotate(90deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle5___3VU2G {
  transform: rotate(120deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle6___1jbtN {
  transform: rotate(150deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle7___1bNvQ {
  transform: rotate(180deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle8___8SpV1 {
  transform: rotate(210deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle9___U_HIk {
  transform: rotate(240deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle10___MV9hA {
  transform: rotate(270deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle11___2Hv8C {
  transform: rotate(300deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle12___3g_P9 {
  transform: rotate(330deg);
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle2___3FIeM:before {
  animation-delay: -1.1s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle3___qZW7t:before {
  animation-delay: -1s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle4___zb7MT:before {
  animation-delay: -0.9s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle5___3VU2G:before {
  animation-delay: -0.8s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle6___1jbtN:before {
  animation-delay: -0.7s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle7___1bNvQ:before {
  animation-delay: -0.6s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle8___8SpV1:before {
  animation-delay: -0.5s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle9___U_HIk:before {
  animation-delay: -0.4s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle10___MV9hA:before {
  animation-delay: -0.3s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle11___2Hv8C:before {
  animation-delay: -0.2s;
}
.Spinner__sk-circle___3oAVZ .Spinner__sk-circle12___3g_P9:before {
  animation-delay: -0.1s;
}
@keyframes Spinner__sk-circleBounceDelay___3v8TE {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
