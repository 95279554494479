.CustomDropdown__dropdown___gks0b {
  max-height: 25vh;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #FAFAFA;
  border: 1px solid #C0C0C0;
  z-index: 2;
}
.CustomDropdown__text___HYhf1 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.CustomDropdown__select___3wQ7x {
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}
