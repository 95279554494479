.FloatingChatTrigger__icon___3DqTr {
  cursor: pointer;
  margin: 0 1rem;
}
.FloatingChatTrigger__icon___3DqTr:hover {
  color: var(--primary) !important;
}
.FloatingChatTrigger__icon___3DqTr.FloatingChatTrigger__sm___1L4S6 {
  font-size: 1.8rem;
}
.FloatingChatTrigger__icon___3DqTr.FloatingChatTrigger__md___7nZoR {
  font-size: 2.5rem;
}
