.SelectWrapper__wrapper___3gGGD {
  width: 100%;
  background-color: #FAFAFA;
  border: 1px solid #F1F1F1;
  cursor: pointer;
}
.SelectWrapper__wrapper-open___2YcRL {
  background-color: #FAFAFA;
  border-color: #F1F1F1;
}
.SelectWrapper__disabled___2k_Vr {
  color: #C0C0C0;
  cursor: default;
}
.SelectWrapper__button___3uXVB {
  box-shadow: none;
  border: none;
  background: transparent;
  padding: 0.8rem 1.2rem;
  width: 100%;
  text-align: left;
  outline: none;
}
.SelectWrapper__icon___2lm-5 {
  margin-left: auto;
  height: 2rem;
}
.SelectWrapper__list___32MYs {
  display: none;
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  background: #F1F1F1;
  max-height: 20rem;
  min-width: calc(100% + 3px);
  overflow-y: auto;
  z-index: 10;
  border: 1px solid #C0C0C0;
  border-top: none;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
}
.SelectWrapper__list-open___2QeLg {
  display: block;
}
