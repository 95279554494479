.VerificationLabel__divider___TNJi8:after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 1px;
  border-right: 1px solid #d7d9d9;
}
.VerificationLabel__divider___TNJi8:last-child:after {
  border-right: 0;
}
.VerificationLabel__verify___Z6fGi {
  display: flex;
  width: 100%;
}
