.SocialBadge__badge___3VVFD {
  color: white;
  border-radius: 50%;
}
.SocialBadge__icon___U6F-S {
  top: 50%;
  left: 50%;
  width: 27px;
  position: absolute;
  transform: translate(-50%, -50%);
}
