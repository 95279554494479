.CardProfile__card___1MuON {
  width: 317px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  padding: 24px 16px;
  border-radius: 16px;
  transition: all 0.2s;
  flex-direction: column;
  justify-content: space-between;
}
.CardProfile__card__content___3pBCY {
  display: flex;
  align-self: center;
  flex-direction: column;
}
.CardProfile__card__content___3pBCY > img {
  width: 86px;
  height: 24px;
  margin: auto;
}
.CardProfile__card__content___3pBCY > p {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #272727;
  margin-bottom: 16px;
  font-family: Proxima Nova;
}
.CardProfile__card__list___2rtkA {
  margin: 0;
  list-style-type: none;
}
.CardProfile__card__list___2rtkA > li {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #272727;
  font-family: Proxima Nova;
  justify-content: flex-start;
}
.CardProfile__card__button___j_4iA {
  margin-top: 16px;
  border-radius: 2px;
  padding: 1.3rem 3.5rem;
  text-transform: capitalize;
}
.CardProfile__card__button--lite___NCPtl {
  border-color: #80ed99;
  background-color: #80ed99;
}
.CardProfile__card__selected___JsvXn {
  border: 1px solid var(--primary);
  background-color: #DBFCFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
}
.CardProfile__card__selected--lite___3GBUq {
  border: 1px solid #80ed99;
  background-color: #ECFFF0;
}
