.ChatTextarea__textarea___rn1Oj {
  width: 100%;
  padding: 1.5rem 8rem 1rem 1rem;
  border-style: none;
  border-top: 1px solid #C0C0C0;
  outline: none;
  resize: none;
  background: transparent;
  transition: 0.2s all;
}
.ChatTextarea__textarea___rn1Oj:focus:not(.ChatTextarea__disabled___2MkW_),
.ChatTextarea__textarea___rn1Oj.ChatTextarea__focus___C3A9V:not(.ChatTextarea__disabled___2MkW_) {
  border-top: 1px solid var(--primary);
}
.ChatTextarea__textarea___rn1Oj:focus:not(.ChatTextarea__disabled___2MkW_)::-webkit-input-placeholder,
.ChatTextarea__textarea___rn1Oj.ChatTextarea__focus___C3A9V:not(.ChatTextarea__disabled___2MkW_)::-webkit-input-placeholder {
  color: #F1F1F1;
}
.ChatTextarea__textarea___rn1Oj:focus:not(.ChatTextarea__disabled___2MkW_)::-moz-placeholder,
.ChatTextarea__textarea___rn1Oj.ChatTextarea__focus___C3A9V:not(.ChatTextarea__disabled___2MkW_)::-moz-placeholder {
  color: #F1F1F1;
}
.ChatTextarea__textarea___rn1Oj.ChatTextarea__with-images___1BILy {
  padding-top: 10rem;
}
.ChatTextarea__icon-container___1Kj_W {
  position: absolute;
  bottom: 1rem;
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.ChatTextarea__icon___3AHLy {
  font-size: 2rem;
  margin-top: 0.8rem;
}
.ChatTextarea__icon___3AHLy:not(.ChatTextarea__disabled___2MkW_) {
  color: #767676;
  cursor: pointer;
}
.ChatTextarea__icon___3AHLy:not(.ChatTextarea__disabled___2MkW_):hover {
  color: var(--primary);
}
.ChatTextarea__icon___3AHLy.ChatTextarea__disabled___2MkW_ {
  color: #F1F1F1;
  cursor: default;
}
.ChatTextarea__images-container___2100c {
  position: absolute;
  top: 0;
  left: 0;
  right: 1rem;
  z-index: 1;
}
.ChatTextarea__image___pV47R {
  height: 7.5rem;
  min-width: 20%;
  max-width: 25%;
  border-radius: 0.25rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.12);
  background-position: center;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
}
.ChatTextarea__image-overlay___1fFKb {
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.ChatTextarea__image-overlay___1fFKb:hover {
  opacity: 1;
}
.ChatTextarea__image-overlay___1fFKb i {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: #FAFAFA;
}
.ChatTextarea__image-overlay___1fFKb i:hover {
  color: var(--primary);
}
