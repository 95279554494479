.SensitiveContentTag__tag___2OU5c {
  color: white;
  background-color: black;
  padding: 0.4rem 1.5rem;
  font-size: 1.4rem;
  font-weight: lighter;
  display: inline-block;
  border-radius: 20px;
  line-height: 1.4rem;
}
