.LocationSelectUI__input___1Y8u6 {
  border: none;
  padding: 0;
  background: transparent;
}
.LocationSelectUI__input___1Y8u6:focus {
  border: none;
}
.LocationSelectUI__tree-item___2_sKT {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.LocationSelectUI__tree-item___2_sKT:hover {
  background: #F1F1F1;
}
.LocationSelectUI__tree-item___2_sKT:hover span {
  display: block;
}
.LocationSelectUI__tree-item___2_sKT span {
  cursor: pointer;
  display: none;
  font-size: 1rem;
  margin-left: 1rem;
}
.LocationSelectUI__tree-item___2_sKT span:hover {
  color: var(--primary);
}
