.VerifyModalLite__dialog___Vps8q {
  border-radius: 4px;
  background-color: white;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__dialogContainer___3fLwA {
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__header___1Fp70 {
  display: flex;
  font-size: 16px;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__container___UWoRl {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__container___UWoRl .VerifyModalLite__title___1a5LE {
  color: #16d4e6;
  font-size: 18px;
  font-weight: 600;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__container___UWoRl .VerifyModalLite__text___1hzIc {
  font-size: 12px;
  font-weight: normal;
  color: var(--black);
  margin-top: 3rem;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__buttonContainer___YkTkN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.VerifyModalLite__dialog___Vps8q .VerifyModalLite__buttonContainer___YkTkN .VerifyModalLite__button___Q0qaW {
  margin-top: 3rem;
  width: 18rem;
}
