.Stepper__step___1gUp3 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 40px;
  color: white;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
}
.Stepper__disabled___AwuxN {
  opacity: 0.5;
}
.Stepper__active___af7yu {
  opacity: 1;
}
