.ParticipationProducts__title___38I9Y {
  width: 95%;
  margin: 0 1rem 0 0;
}
.ParticipationProducts__product___38gKR {
  width: 100%;
  display: flex;
  height: 22rem;
  color: #505050;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.ParticipationProducts__product--lite___3C53p {
  z-index: 1;
  width: 110%;
  margin-top: 5rem;
  position: relative;
}
.ParticipationProducts__product--lite-influencer___3LV8I {
  z-index: 1;
  width: 100%;
  margin-top: 2.5rem;
  position: relative;
}
.ParticipationProducts__image___3NKm8 {
  height: 100%;
  width: 25.75rem;
  background-size: cover;
  background-position: center;
}
.ParticipationProducts__details___35NqZ {
  position: relative;
  width: calc(100% - 25.75rem);
}
.ParticipationProducts__btn-trash___2Caz9 {
  padding: 1rem;
  border: none !important;
}
.ParticipationProducts__btn-trash___2Caz9 > img {
  width: 15px;
  height: 16px;
}
.ParticipationProducts__delivery__edit___3E80m {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1025px) {
  .ParticipationProducts__product--lite-influencer___3LV8I {
    height: 100%;
  }
}
