.Pulse__pulse___FTzYW {
  animation: Pulse__pulse___FTzYW 2s infinite;
}
@keyframes Pulse__pulse___FTzYW {
  0% {
    box-shadow: 0 0 0 0 var(--primaryF60);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
