.BrandAvatar__image___2kPuZ {
  overflow: hidden;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}
.BrandAvatar__image___2kPuZ .BrandAvatar__overlay___3DADS {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FAFAFA;
  opacity: 0;
  transition: all 0.2s;
}
.BrandAvatar__image___2kPuZ.BrandAvatar__sm___2aKkW {
  width: 4rem;
  height: 4rem;
  font-size: 0.75rem;
}
.BrandAvatar__image___2kPuZ.BrandAvatar__md___2qLQN {
  height: 7rem;
  width: 7rem;
  font-size: 2rem;
}
.BrandAvatar__image___2kPuZ:hover .BrandAvatar__overlay___3DADS {
  opacity: 1;
}
.BrandAvatar__shadow___28aYl {
  box-shadow: 0 0.75rem 1.5rem 0 rgba(0, 0, 0, 0.4);
}
