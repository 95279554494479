.CardForm__card___f1RRD {
  width: 100%;
  padding: 32px 48px;
  border-radius: 24px;
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.CardForm__card--small-padding___2ho2S {
  padding: 32px 40px;
}
.CardForm__card___f1RRD .CardForm__title___3P6Wm {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  color: #1F2626;
}
.CardForm__card___f1RRD .CardForm__sub-title___1aFtr {
  font-size: 24px;
  font-weight: 100;
  text-align: center;
  line-height: normal;
  margin: 0.5rem 0 2.5rem;
  color: #767676;
}
.CardForm__card___f1RRD .CardForm__sub-title--black___2PPib {
  color: #1F2626;
  margin: 0 0 3rem 0;
}
@media (max-width: 768px) {
  .CardForm__card___f1RRD {
    width: 90%;
    padding: 3rem;
    margin: 40px auto;
  }
  .CardForm__card___f1RRD .CardForm__title___3P6Wm,
  .CardForm__card___f1RRD .CardForm__sub-title___1aFtr {
    font-size: 18px;
  }
}
