.ImagePreviewUI__wrapper___33ujQ:hover button {
  opacity: 1;
}
.ImagePreviewUI__overlay___vQXqD {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  font-size: 2.5rem;
}
