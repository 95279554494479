.SearchBar__wrapper___3GcaP {
  display: flex;
  flex-wrap: wrap;
  min-height: 6rem;
  width: 100%;
  box-shadow: inset 0 0 0 1px #F1F1F1, 0 4px 1rem 0 rgba(0, 0, 0, 0.04);
  font-size: 3.3rem;
  align-items: center;
  padding: 1.75rem 2.25rem 1.75rem 3rem;
  background-color: white;
  border-radius: 2px;
}
.SearchBar__wrapper___3GcaP form {
  flex-grow: 1;
}
.SearchBar__search-button___2vyZx {
  max-height: 4rem;
  margin-right: 2rem;
  cursor: pointer;
  box-shadow: none;
  background-color: white;
  border: none;
  font-size: inherit;
  outline: none;
}
.SearchBar__input___1xvfv {
  outline: none;
  border: none;
  flex-grow: 1;
  height: 100%;
  font-size: inherit;
  width: 100%;
}
.SearchBar__selector-wrapper___2pqkg {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--primary);
  font-weight: bold;
  margin-left: 1.8rem;
  padding-left: 1.8rem;
  border-left: 1px solid var(--primary);
  user-select: none;
}
.SearchBar__caret-down___3Hr5u {
  margin-left: 2rem;
  max-height: 3.9rem;
}
.SearchBar__interest-wrapper___2JvOV {
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 1px solid #F1F1F1;
}
.SearchBar__tag___11GgH {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.8rem;
  border: 1px solid black;
  margin-right: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  user-select: none;
}
.SearchBar__tag___11GgH span {
  font-size: 1.5rem;
  margin-left: 1.5rem;
  max-height: 1.9rem;
}
.SearchBar__tag-selected___1vniw {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: white;
}
.SearchBar__selected-wrapper___29ceZ {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1.8rem;
  background-color: transparent;
}
.SearchBar__sticky___1Pe90 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 10px;
  z-index: 110;
  background-color: white;
  transition: all 0.2s;
}
.SearchBar__stick-button___3arS2 {
  max-height: 2.8rem;
}
