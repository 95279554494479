.PostStatsGridUI__stats-grid___2Vd3e {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0;
}
.PostStatsGridUI__reaction-image___88y5J {
  width: 15px;
  height: 15px;
  margin-left: 1rem;
}
.PostStatsGridUI__info___12kXI {
  color: var(--primary);
  font-size: 1.8rem;
  font-weight: lighter;
}
