.RateSelector__container___2wWwn {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 22rem;
  height: 4rem;
  border-radius: 4rem;
  background-color: #F1F1F1;
}
.RateSelector__container___2wWwn i {
  font-size: 1.75rem;
  color: #C0C0C0;
}
.RateSelector__hovered___p3e3_ {
  background-color: rgba(22, 212, 230, 0.1) !important;
}
.RateSelector__highlighted___278Wh {
  color: #FFFFFF !important;
  text-shadow: -1px 0 var(--primary), 0 1px var(--primary), 1px 0 var(--primary), 0 -1px var(--primary);
}
.RateSelector__active-primary___67C4F {
  color: var(--primary) !important;
}
.RateSelector__active-gold___268tl {
  color: #FEBE2D !important;
}
.RateSelector__transparent___23hg1 {
  background-color: transparent !important;
}
