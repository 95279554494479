.FormLabel__label___2B_Vs {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  margin: 2rem 0 1rem;
  color: #272727;
}
@media (min-width: 768px) {
  .FormLabel__label___2B_Vs {
    font-size: 18px;
  }
}
