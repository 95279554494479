.UserAvatarDropdownUI__avatar___3PqwW {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}
.UserAvatarDropdownUI__noti___1gUrr {
  right: 0;
  left: inherit;
  bottom: 0px;
  width: 12px;
  height: 12px;
  box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.05);
}
.UserAvatarDropdownUI__arrow___PAJXD {
  gap: 0.5rem;
  display: flex;
  color: white;
  align-items: center;
}
