.FloatingChat__container___hYzw3,
.FloatingChat__name___w6TZJ {
  transition: all 0.2s;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.FloatingChat__focused___PnfBg {
  box-shadow: 0 0 0 1px var(--primary);
}
.FloatingChat__header___uRDMD {
  height: 4rem;
  width: 100%;
  color: #FAFAFA;
  background-color: #1f2626;
  cursor: pointer;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  z-index: 1;
}
.FloatingChat__close___1XR6r {
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 0.375rem;
}
.FloatingChat__close___1XR6r:hover {
  color: var(--primary);
}
.FloatingChat__body___2BzrO {
  height: 65vh;
  background-color: #FAFAFA;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
}
.FloatingChat__badge___6T26- {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  font-size: 1.2rem;
  background-color: #F85555;
  color: #FAFAFA;
}
.FloatingChat__brand-switch___165LX {
  border-bottom: 1px solid #F1F1F1;
}
