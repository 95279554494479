.ProductsBriefLite__title___AT2jF {
  width: 95%;
  font-size: 14px;
  font-weight: 600;
  margin: 0 1rem 0 0;
  line-height: normal;
  color: #272727;
  font-family: Proxima Nova;
}
.ProductsBriefLite__text___Hx8Rm {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: #767676;
  font-family: Proxima Nova;
}
.ProductsBriefLite__product___11taf {
  z-index: 1;
  width: 100%;
  display: flex;
  height: 22rem;
  position: relative;
  border-radius: 8px;
  margin: 24px 0 32px;
  color: #505050;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}
.ProductsBriefLite__image___mNeVR {
  height: 100%;
  width: 25.75rem;
  background-size: cover;
  background-position: center;
}
.ProductsBriefLite__image___mNeVR > div > div {
  border-radius: 8px 0 0 8px;
}
.ProductsBriefLite__radius___3EPBQ {
  border-radius: 8px 0 0 8px;
}
.ProductsBriefLite__details___3uieX {
  position: relative;
  width: calc(100% - 25.75rem);
}
.ProductsBriefLite__details___3uieX .ProductsBriefLite__price___1IQm0 {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 670px) {
  .ProductsBriefLite__title___AT2jF {
    font-size: 18px;
  }
  .ProductsBriefLite__text___Hx8Rm {
    font-size: 14px;
  }
  .ProductsBriefLite__product___11taf {
    height: auto;
    flex-direction: column;
  }
  .ProductsBriefLite__image___mNeVR {
    width: 100%;
    min-height: 135px;
  }
  .ProductsBriefLite__image___mNeVR > div > div {
    border-radius: 8px 8px 0 0;
  }
  .ProductsBriefLite__radius___3EPBQ {
    border-radius: 8px 8px 0 0;
  }
  .ProductsBriefLite__details___3uieX {
    width: 100%;
    position: relative;
  }
  .ProductsBriefLite__details___3uieX .ProductsBriefLite__price___1IQm0 {
    width: 100%;
    justify-content: space-between;
  }
}
