.TagsSearcher__label___1zva0 {
  cursor: pointer;
}
.TagsSearcher__plus___8LYSr {
  color: var(--primary);
  font-weight: bold;
  font-size: 2rem;
}
.TagsSearcher__tags___3AH0Q {
  color: var(--primary);
}
.TagsSearcher__tag___1kcYG {
  margin: 1rem 1rem 0 0;
  border: 1px solid #767676;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0.2rem 1.5rem;
  color: #767676;
}
.TagsSearcher__tag___1kcYG span {
  cursor: pointer;
  font-size: 1.2rem;
  display: block;
  margin-left: 1rem;
  height: 11px;
  color: #1f2626;
}
.TagsSearcher__input___2PqLM {
  position: absolute;
  top: 4rem;
  left: 0;
  border-radius: 5px;
  padding: .5rem;
  border: 1px solid #d7d9d9;
  width: 100%;
  z-index: 2;
}
.TagsSearcher__results-container___1VnN8 {
  position: absolute;
  overflow-y: auto;
  max-height: 25vh;
  top: 3rem;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #C0C0C0;
  font-size: 1.3rem;
  z-index: 4;
}
.TagsSearcher__result-item___2C5j1 {
  width: 100%;
  padding: 1em;
  color: #767676;
  background-color: #FFFFFF;
  border-bottom: 1px solid #F1F1F1;
  font-size: 1.3rem;
  cursor: pointer;
}
.TagsSearcher__result-item___2C5j1:hover {
  background-color: #FAFAFA;
}
