.connectionDialogUI__dialog___Tc3Ra {
  width: 50vw;
  max-width: 500px;
  overflow: hidden;
  border-radius: 4px;
  background-color: white;
}
.connectionDialogUI__dialogContainer___2mxfK {
  padding: 15px;
}
.connectionDialogUI__formLabel___OSt2u {
  font-weight: bold;
  color: black;
  margin: .8em 0;
}
.connectionDialogUI__button___253ct {
  margin-top: 1.5em;
}
.connectionDialogUI__headerIcon___38xlI {
  width: 3em;
}
.connectionDialogUI__errorContainer___20oex {
  min-height: 1.2em;
  max-height: 1.2em;
  margin-top: .3em;
}
.connectionDialogUI__errorLabel___24mqB {
  color: red;
  font-size: .8em;
  margin: 0;
}
