.RoleSteps__container___2iWLK,
.RoleSteps__vertical___1KBg8 {
  display: flex;
  padding-bottom: 65px;
  flex-direction: column;
  justify-content: space-between;
}
.RoleSteps__container___2iWLK .RoleSteps__content___XH-EK,
.RoleSteps__vertical___1KBg8 .RoleSteps__content___XH-EK {
  display: block;
  padding: 10px 40px;
}
.RoleSteps__container___2iWLK .RoleSteps__content___XH-EK .RoleSteps__item___pkDxR .RoleSteps__icon___136OP,
.RoleSteps__vertical___1KBg8 .RoleSteps__content___XH-EK .RoleSteps__item___pkDxR .RoleSteps__icon___136OP {
  width: 32px;
  margin: 10px auto;
}
.RoleSteps__container___2iWLK .RoleSteps__content___XH-EK .RoleSteps__item___pkDxR .RoleSteps__text___3tD7m,
.RoleSteps__vertical___1KBg8 .RoleSteps__content___XH-EK .RoleSteps__item___pkDxR .RoleSteps__text___3tD7m {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  color: #575757;
  font-family: Roboto Condensed;
}
.RoleSteps__container___2iWLK .RoleSteps__content___XH-EK .RoleSteps__arrow___2-Z-W,
.RoleSteps__vertical___1KBg8 .RoleSteps__content___XH-EK .RoleSteps__arrow___2-Z-W {
  display: none;
}
.RoleSteps__links___X9SC6 {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 32px 40px;
  flex-direction: column;
}
.RoleSteps__links___X9SC6 > a {
  flex: 1;
}
.RoleSteps__links___X9SC6 > a > button {
  width: 100%;
}
@media (min-width: 769px) {
  .RoleSteps__container___2iWLK {
    position: relative;
    flex-direction: row;
    padding-bottom: 120px;
  }
  .RoleSteps__container___2iWLK .RoleSteps__content___XH-EK {
    padding: 0;
    display: flex;
  }
  .RoleSteps__container___2iWLK .RoleSteps__content___XH-EK .RoleSteps__arrow___2-Z-W {
    width: 32px;
    display: block;
    margin: 0 70px;
    align-self: center;
  }
  .RoleSteps__links___X9SC6 {
    bottom: 0;
    gap: 32px;
    display: flex;
    margin: 32px 0;
    padding: 32px 0;
    position: absolute;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .RoleSteps__container___2iWLK .RoleSteps__content___XH-EK .RoleSteps__item___pkDxR .RoleSteps__text___3tD7m {
    font-size: 22px;
  }
  .RoleSteps__links___X9SC6 {
    margin: 0;
    position: static;
  }
  .RoleSteps__hidden___1ar1J {
    display: none;
  }
}
